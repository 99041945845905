import React from 'react';
import Card from './../Card'; 
import data from '../../../data/data.json';

const PowerSection: React.FC = () => {
  return (
    <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-2">
      {data.header.map(({ title, image, content }, index) => (
        <Card key={index}>
          <img alt={title} src={image} className="mx-auto max-w-3/5" />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{content}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  )
};

export default PowerSection;
