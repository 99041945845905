import InternalSection from './Section'
import SectionContainer from './Container'

type InternalSectionType = typeof InternalSection

interface SectionInterface extends InternalSectionType {
  Container: typeof SectionContainer
}

const Section = InternalSection as SectionInterface
Section.Container = SectionContainer
export default Section
