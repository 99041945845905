import React from 'react'
import classNames from 'classnames'

const Title: React.FC<{
  className?: string
  children: React.ReactElement | React.ReactElement[] | string
}> = props => {
  const { className, children } = props
  return (
    <h3
      {...props}
      className={classNames('text-lg', className)}
      children={children}
    />
  )
}
export default Title
