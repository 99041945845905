import React from 'react'
import classNames from 'classnames'

const Body: React.FC<{
  className?: string
  children: React.ReactElement | React.ReactElement[] | string
}> = props => {
  const { className, children } = props
  return <div {...props} className={classNames('card-body', className)} children={children} />
}
export default Body
