import InternalCard from './Card'
import Body from './Body'
import Title from './Title'
import Text from './Text'

type InternalCardType = typeof InternalCard

interface CardInterface extends InternalCardType {
  Body: typeof Body
  Text: typeof Text
  Title: typeof Title
}

const Card = InternalCard as CardInterface
Card.Body = Body
Card.Title = Title
Card.Text = Text
export default Card
