import React from 'react';
import classNames from 'classnames';
import SEO from '../../components/client/SEO';
import Section from '../../components/client/Section';
import InfoPage from '../../components/client/InfoPage';
import Footer from '../../components/client/Footer';
import Card from '../../components/client/Card';
import HeaderSection from './sections/Header';
import data from '../../data/data.json';
import firstGapImageBackground from '../../assets/images/covers/cover1.jpg';
import CardSection from '../../components/client/Section/CardSection';

interface IUserHomeProps {
  userId:any  
}

export const UserHome: React.FC<IUserHomeProps> = props => {
  // Removed unused state setters


  return (
    <div
      className="App-header"
      style={{
        left: '0px',
        position: 'relative',
        top: '100px',
        backgroundColor: 'inherit',
        color: 'inherit',
        width: 'calc(100%)',
        margin: '0px 0px',
        padding: '0px 0px 0px 0px'
      }}
    >
      <SEO title="Pool Power Saving" />
      {/** HEADING SECTION */}  
      <HeaderSection />
      {/* <Section gap image={firstGapImageBackground} className="hidden sm:block" /> */}
      <Section gap image={firstGapImageBackground} className="hidden sm:block"/>
      <Section header className="flex flex-col items-center text-white">
        <Section.Container className="mb-2">
          <InfoPage index={0}/>
        </Section.Container>
        <Section gap image={firstGapImageBackground} />
        <CardSection order={0} mtitle={"benefits"} schema={''} />
        <Section gap image={firstGapImageBackground} />
        <Section.Container className="mb-2">
          <h2 className="mb-2 pt-10 text-white">Features</h2>
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-1 lg:grid-cols-2">
            {data.features[0].cards.map(({title,image,content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto max-w-32" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container>
        <Section className={classNames('bg-secondary', 'text-center text-white')}>
        <InfoPage index={1} />
        <Section gap image={firstGapImageBackground} />
        <Section.Container className="mb-2">
          <h2 className="mb-2 pt-10 text-white">PPS Features</h2>
          <div className="mb-2 grid grid-cols-1 gap-1 md:grid-cols-2 lg:grid-cols-2">
            {data.products[0].cards.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto max-w-72" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container>
        </Section>
      </Section>
      <Section gap image={firstGapImageBackground} /> 
      <Footer />
  </div>
  );
};
