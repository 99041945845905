import React from 'react'
import { Helmet } from 'react-helmet'

interface SEOProps {
  title?: string
}

const SEO: React.FC<SEOProps> = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title ? `${title} — Australia` : 'Pool Power Saving Australia'}</title>
    <meta name="description" content="Pool Power Saving" />
    <meta name="robots" content="max-image-preview:large" />
    <link rel="canonical" href="https://ppsaus.com" />
  </Helmet>
)
export default SEO
