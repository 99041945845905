import React from 'react'
import classNames from 'classnames'

interface ICardProps {
  children: React.ReactElement | React.ReactElement[] | string
  className?: string
}

const Card: React.FC<ICardProps> = props => {
  const { className, children } = props
  return (
    <div {...props} className={classNames('card', 'space-y-4', className)} children={children} />
  )
}
export default Card
