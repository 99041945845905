import React from 'react'
import classNames from 'classnames'
import data from '../../../data/data.json';
import Section from '../../../components/client/Section'
import PowerSection from '../../../components/client/Section/PowerSection'
import backgroundCoverImage from '../../../assets/images/covers/cover1.jpg'
import PowerVideo from '../../../components/client/Section/PowerVideo'
import Card from '../../../components/client/Card';

const HeaderSection: React.FC = () => {
  // Removed unused variables
  return(
    <Section
      header
      gradient
      image={backgroundCoverImage}
      className={classNames('flex flex-col items-center', 'text-white')}
    >
    <Section.Container className="lg:py-8">
      <div className="intro-heading" style={{padding:'0px 0px'}}>
          <h2 className="mb-10">Our Mission and Vision</h2>
          <Section smallgap image={backgroundCoverImage} className="hidden sm:block" />
          <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
            <PowerSection />
          </div>
        </div>
    </Section.Container>
    <Section.Container className="elementor-container elementor-column-gap-default">
      {data.video.map(({ title, image, content }, index) => (
        <Card key={index}>
          <Card.Body>
              <PowerVideo videoId={content}
              imageUrl={image}
              altText="Play"/> 
          </Card.Body>
        </Card>
      ))}
    </Section.Container>
  </Section>
  )
}
 
export default HeaderSection
