import React from 'react'
import classNames from 'classnames'

import InternalContainer, { IContainerProps } from '../Container'

/**
 * The section container differs
 * from the internal container only by
 * the fact that the section container
 * absolutely needs to have a relative position
 * (in order to manage background images)
 */
const Container: React.FC<IContainerProps> = props => {
  const { className, children } = props
  return (
    <InternalContainer {...props} className={classNames('relative', className)}>
      {children}
    </InternalContainer>
  )
}
export default Container
