import React from 'react'
import classNames from 'classnames'
import { Card } from 'react-bootstrap'
import data from '../../../data/data.json';
import Section from './Section';

interface ICardSecProps {
    order:number,
    mtitle: String,
    schema: string,
}
const CardSection: React.FC<ICardSecProps> = props => {
  const {mtitle, schema} = props
  return (
    <Section className="mb-2">
        <h2 className="mb-2 pt-10 text-white">{mtitle}</h2>
        <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-1 lg:grid-cols-2">
        {
            data.benefits[0].cards.map(({title,image,content}, index) => (
                <Card key={index}>
                    <img alt={title} src={image} className="mx-auto max-w-32" />
                    <Card.Body>
                        <h2 className="mx-auto pt-10 max-w-10" >
                            <Card.Title><strong>{title}</strong></Card.Title>
                        </h2>
                        <Card.Text className="card-text">{content}</Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </div>
    </Section>
  )
}
export default CardSection;
