import React from 'react'
import { Link } from 'react-router-dom';
import data from '../../data/data.json';
import Section from './Section'

export default function Footer() {
  return (
  <footer className="footer text-primary" style={{ height: '60', padding: '0% 0', backgroundColor: '#436481' }}>
      <Section.Container className="elementor-container elementor-column-gap-default">
        {data.footer.logo.map(({ title, image, content }, index) => (
          <div className="flex justify-center" key={index}>
          <Link to={'/'}><img src={image} className="mx-auto max-h-96" alt={title} />
          </Link>
        </div>
        ))}
      </Section.Container> 
      <div className="content-center text-sm text-center"
      >
        <div className="row" style={{ paddingTop: '5px' }}/>
          <span
            className="mx-auto text-sm text-center"
            style={{
              width: 'auto',
              display: 'block',
            }} >
            <Section.Container className="elementor-container elementor-column-gap-default">
              {data.footer.socials.map(({ title, image, content }, index) => (
                <span
                  className="badge "
                  key={`social-${index}`} style={{
                    borderRadius: '5px',
                    fontSize: '30px',
                    backgroundColor: '#f9f4e8',
                    color: '#11aedc',
                    borderColor: '#11aedc',
                    borderWidth: '5px',
                    marginLeft: '5px',
                    marginTop: '5px',
                    padding: '0px 5px',
                  }} >
                  <a target="_blank" title={title} rel="noopener noreferrer" style={{ color: 'inherit' }} href={image}>
                    {' '}
                    <span className={content} style={{ padding: '10px' }}></span>
                  </a>
              </span> 
              ))}
            </Section.Container>
          </span>
        <Section.Container className="mt-2 py-6"
        >
          {data.footer.businfo.map(({ title, image, content }, index) => (
              <ul key={`businfo-${index}`}> <li>
                <strong>{title}</strong><p>{content}</p>
              </li>
              <li>{image}</li>
              </ul>
            ))
          }
        </Section.Container>
        <Section.Container className="mt-2 py-4">
          {data.footer.copytext.map(({ title, image, content }, index) => (
            <div className="footer-copy" key={`copytext-${index}`}>&copy;{content}</div>
            ))
          }
        </Section.Container>
      </div>
    </footer>
  )
}
