import React from 'react'
import classNames from 'classnames'

export type GradientOverlayProps = {
  /**
   * Is the gradient enabled?
   */
  enabled: boolean
}

const GradientOverlay: React.FC<GradientOverlayProps> = ({ enabled }) => (
  <>
    {
      /**
       * Add a gradient element if enabled.
       */
      enabled ? (
        <div
          className={classNames(
            'absolute',
            'left-0',
            'right-0',
            'top-0',
            'bottom-0',
            'bg-transparent',
            'bg-gradient-to-b',
            'from-secondary/80',
            'to-secondary/100'
          )}
        />
      ) : null
    }
  </>
)

export default GradientOverlay
