import React from "react";
import Section from "./Section";
import classNames from "classnames";
import data from "../../data/data.json";

interface InfoPageProps {
  index: number;
}
class InfoPage extends React.Component<InfoPageProps> {
  render() {
    const { index } = this.props;
    const infoContent = data.infopage.find(info => info.order === index);
    if (!infoContent) return null;

    const sectionStyle = { paddingLeft: '20%', paddingRight: '20%' };
    return (
      <Section 
        header  
        className={classNames("flex flex-col items-center", "text-white")}>
        <React.Fragment key={infoContent.order}>
          {infoContent.idx.map(({ title, par1, par2, pointL }, index1) => (
            <React.Fragment key={index1}>
              <h1 className="text-pps font-bold mb-4 text-white">{title}</h1>
              <p style={sectionStyle} className="pp font-semibold">{par1}</p>
              <p style={sectionStyle} className="pp font-semibold">{par2}</p>
              <div className="bg-1 shadow-md rounded-lg p-6">
                {pointL.map(({ pointH, points }, index2) => (
                  <div key={index2}>
                    <h2 className="text-pps font-semibold mb-3">{pointH}</h2>
                    {points.map(({ strong, text }, index3) => (
                      <p style={sectionStyle} key={index3} className="pp">
                        <strong className="pstrong">{strong}</strong>
                        {text}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      </Section>
    );
  }
}

export default InfoPage;
