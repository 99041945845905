import React from 'react';
interface VideoProps {
  videoId: string;
  imageUrl: string;
  altText: string;
}

const PowerVideo: React.FC<VideoProps> = ({ videoId, imageUrl, altText }) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;
  const playVideo = () => {
    window.open(videoUrl, "_blank");
  };  

  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#436481]" style={{ height: '500px' }}>
      <div className="aspect-w-16 aspect-h-9 w-full max-w-screen-md mx-auto relative" style={{ height: '450px' }}>
        <iframe
            className="w-full h-full"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={altText}
        ></iframe>
        <div 
          className="absolute top-0 left-0 w-72 flex items-center justify-center cursor-pointer" 
          onClick={playVideo} 
          role="button" 
          aria-label="Play" 
          tabIndex={0}
          style={{
              transform: 'translate(100%, 100%)' // Moves the play button to the correct position, adjusting from the top-left corner
          }}
          >
            <div className="text-white text-6xl">
                {/* Replace this with an actual icon or SVG */}
                <i className="eicon-play"></i>
            </div>
          </div>
        </div>
    </div>
  );
};

export default PowerVideo;
