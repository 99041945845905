/* eslint-disable import/no-anonymous-default-export */
//REACT
import { BrowserRouter as Router, Link } from "react-router-dom";

//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Styles
import "@fortawesome/fontawesome-free/css/all.min.css";
//INTERNAL STYLES
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Header.css";
import "./CustomStyle.css";
import {UserHome} from "./screens/home/UserHome";
import PPS4 from "./assets/images/PPS4.png";

function App() {

  return (
    <div className="App">
      <div
        style={{ display: "block" }}
      >
        <Router>
          {/* TOP RIGHT-HAND HEADER BUTTON SECTION */}
          <div className="container">
            <div className="row" style={{ paddingTop: "50px" }}>
            </div>
          </div>
          {/*PRO SIDEBAR */}
          <div
            id={"header"}
            style={{
              maxWidth: "auto",
              top: "120px",
              overflow: "auto",
              height: "107vh",
              zIndex: "19999999",
              borderRight: "0.5px solid rgb(140 140 140 / 42%)",
            }}
            className="custom-slider"
          ></div>
          <header
            style={{
              textAlign: "left",
              float: "left",
              width: "100%",    
              zIndex: "99999",
              height: "150px",
              overflow: "hidden",
              position: "fixed",
              left: "0px",
              top: "0px",
              borderBottom: "0.5px solid rgb(140 140 140 / 42%)",
              padding: "5px 8px",
              margin: "0px",
              cursor: "pointer",
            }}
            className="custom-slider"
          >
            <Link to="/">
              <img
                src={PPS4}
                alt=" "
                style={{
                  display: "inline-block",
                  height: "120px",
                  width: "120px",
                  marginTop: "10px",
                  marginRight: "20px",
                }}
              />
            </Link>
          </header>
          {/* MAIN CONTAINER */}
          <div
            id="main"
            ><UserHome />
          </div>
        </Router>
      </div>
    </div>
  );
}
export default () => <App/>;